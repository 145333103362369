<template>
	<div
		class="bid"
		:class="{
			[getType]: true,
			[getBackgroundColour]: true,
		}"
	>
		<div class="value">
			{{ getValue }}
		</div>
		<suit-symbol
			v-if="getShowSuitSymbol"
			:suitName="getSuit"
			:withoutPadding="true"
		/>
	</div>
</template>

<script>

	import SuitSymbol from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			SuitSymbol
		},
		props: {
			bid: {
				type: Object,
				required: true
			},
			showBackgroundColour: {
				type: Boolean,
				default: false
			},
			showLongPass: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getBackgroundColour () {
				if (!this.bid) {
					return false;
				}
				if (!this.showBackgroundColour) {
					return 'white';
				}
				if (this.bid.type === 'pass') {
					return 'green';
				}
				if (this.bid.type === 'double') {
					return 'red';
				}
				if (this.bid.type === 'redouble') {
					return 'blue';
				}
				return 'white';
			},
			getType () {
				return this.bid.type;
			},
			getValue () {
				if (this.bid.type === 'pass') {
					if (this.showLongPass) {
						return 'Pass';
					}
					return 'P';
				}
				if (this.bid.type === 'double') {
					return 'X';
				}
				if (this.bid.type === 'redouble') {
					return 'XX';
				}
				if (this.bid.suit === 'NT') {
					return this.bid.value + 'NT';
				}
				return this.bid.value;
			},
			getSuit () {
				return this.bid?.suit;
			},
			getShowSuitSymbol () {
				if (!this.getSuit) {
					return false;
				}
				return this.getSuit !== 'NT';
			}
		}
	};

</script>

<style lang="scss" scoped>

	.bid {
		display:flex;
		align-items:center;
		justify-content:center;
		background-color:$c-white;
		.value {
			text-transform:uppercase;
		}

		&.green {
			background-color: $c-green-l;
			.value {
				color: $c-white-l;
			}
		}
		&.red {
			background-color: $c-red;
			.value {
				color: $c-white-l;
			}
		}
		&.blue {
			background-color:$c-darkest-navy;
			.value {
				color: $c-white-l;
			}
		}
	}

</style>
