<template>
	<div
		class="suit"
	>
		<icon
			v-if="getShowIcon"
			:icon="getSuitIcon"
			:colour="getSuitColour"
		/>
		<div
			v-if="cards.length"
			class="suit__cards"
		>
			<div
				class="card"
				v-for="(card, key) in sortedCard"
				:key="key"
			>
				{{ getCard(card) }}
			</div>
		</div>

	</div>
</template>

<script>
	import Icon           from '@/components/ui/Icon';
	import { cardOrder }   from '@/consts';

	export default {
		components: {
			Icon
		},
		props: {
			cards: {
				type: String,
				default: undefined
			},

			suit: {
				type: String,
				default: undefined
			},

			hideEmptySuits: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			getShowIcon () {
				if (!this.cards.length && this.hideEmptySuits) {
					return false;
				}
				return true;
			},

			getSuitIcon () {
				if (!this.suit) {
					return '';
				}
				return this.suit.slice(0, -1);
			},

			getSuitColour () {
				if (!this.suit) {
					return '';
				}
				if (this.suit.toLowerCase() === 'clubs' ||
						this.suit.toLowerCase() === 'spades') {
					return 'black';
				}
				return 'red';
			},

			getShortSuitName () {
				if (!this.suit) {
					return false;
				}
				return this.suit[0].toUpperCase();
			},

			sortedCard () {
				return cardOrder.filter((card) => this.cards.includes(card));
			}
		},

		methods: {
			getCard (card) {
				if (card === 'T') {
					return '10';
				}
				return card;
			},
			getFullCardName (card) {
				if (!this.getShortSuitName ||
						!card) {
					return false;
				}
				return `${card}${this.getShortSuitName}`;
			}
		}
	};

</script>

<style lang="scss" scoped>
	.suit {
		display:flex;
		align-items:center;

		[data-component='icon'] {
			width: auto;
			height: rem(15);
		}

		&__cards {
			display:flex;
			align-items:center;
			flex-wrap:nowrap;
      gap: 6px;
      margin-left: 6px;

			.card {
				display:flex;
				align-items:center;
				flex-wrap:nowrap;

        @include font(15px, $c-darkest-navy, 400, 19px);
			}
		}
	}
</style>
