<template>
	<div class="bidding-interface">
		<V2Auction
			:diagram="diagram"
			:selectedBid="selectedBid"
			@select-bid="selectBid"
			:isConfirmed="isConfirmed"
			:disabled="disabled"
		/>

		<!-- <div class="answer-bid">
			<V2Bid
				v-if="selectedBid"
				:bid="selectedBid"
			/>
		</div> -->

	</div>
</template>

<script>
	import V2Auction                 from '@/components/v2/simulator/bidding/V2Auction';
	// import V2Bid         from '@/components/v2/diagrams/V2Bid';

	export default {
		components: {
			V2Auction
			// V2Bid
		},

		emits: ['select-bid'],

		props: {
			diagram: {
				type: Object,
				required: true
			},

			selectedBid: {
				type: Object
			},

			isConfirmed: {
				type: Boolean,
				default: false
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},

		methods: {
			selectBid (bid) {

				this.$emit('select-bid', bid);
			}
		}
	};

</script>

<style lang="scss" scoped>

.answer-bid {
  ::v-deep .bid {
    justify-content: flex-start !important;
    flex-direction: row !important;
  }
}

.bidding-interface {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .bid {
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 6px;

    .value {
      @include font(18px, $c-dark-navy,500, 23px);
    }

    ::v-deep [data-component="suit-symbol"] {
      [data-component='icon'] {
        height: 19px;
        width: auto;

      }
    }
  }
}

</style>
