<template>
	<div
		class="bidding-table"
	>
		<div class="bidding-table__table">
			<div class="bidding-table__table--positions">
				<div
					v-for="(position, key) in ['W', 'N', 'E', 'S']"
					:key="key"
					class="position"
				>
					{{ position }}
				</div>
			</div>
			<div
				class="bids"
			>

				<div
					class="bid empty-bid"
					v-for="n in emptyBidsAmount"
					:key="n"
				/>

				<div
					class="bid"
					v-for="(bid, key) in getFormattedBids"
					:key="key"
				>
					<div class="value">
						{{ getValue(bid) }}
					</div>
					<icon
						v-if="getHasIcon(bid)"
						:icon="getSuit(bid)"
						:colour="getSuitColour(bid)"
					/>
				</div>
				<div
					class="bid"
					v-if="getPostSymbol"
				>
					{{ getPostSymbol }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Icon                from '@/components/ui/Icon';
	import gameService         from '@/services/gameService';

	export default {
		components: {
			Icon
		},
		props: {
			diagram: {
				type: Object,
				required: true
			}
		},
		data: () => ({
			game: undefined
		}),
		computed: {
			getBids () {
				return this.diagram.config.bids;
			},
			getFormattedBids () {
				return this?.game?.auction?.bids;
			},

			emptyBidsAmount () {
        if (!this.getFormattedBids?.length) {
          return [];
        }

        if (this.getFormattedBids[0].player === 'N') {
          return [1];
        }

        if (this.getFormattedBids[0].player === 'E') {
          return [1, 2];
        }

        if (this.getFormattedBids[0].player === 'S') {
          return [1, 2, 3];
        }

        return [];
			},

			getDealerPosition () {
				return this.diagram.config.dealerPosition;
			},
			getPostSymbol () {
				const postSymbol = this.diagram.config.postSymbol;

				if (!postSymbol ||
						postSymbol === 'none') {
					return false;
				}
				if (postSymbol === 'ellipsis') {
					return '…';
				}
				if (postSymbol === 'questionMark') {
					return '?';
				}
				return '';
			}
		},
		watch: {
			getBids () {
				this.updateGame();
			},
			getDealerPosition () {
				this.updateGame();
			},
			gameState () {
				this.updateGame();
			}
		},
		mounted () {
			this.updateGame();
		},
		methods: {
			async getGame (bids, position) {
				return gameService.getNewGameFromGameState({
					gameState: {
						name: 'Bidding table',
						deal: `${position}:`,
						auction: {
							value: position,
							bids: bids
						}
					}
				});
			},
			async updateGame () {
				if (this.gameState) {
					this.game = await this.getGame(this.gameState.auction.bids, this.gameState.auction.value);
					return;
				}
				if (!this.getBids) {
					this.game = undefined;
					return false;
				}
				if (!this.getDealerPosition) {
					return false;
				}
				const bids = this.getBids.split(',');
				if (bids[bids.length - 1] === '') {
					return false;
				}
				try {
					const game = await this.getGame(bids, this.getDealerPosition);
					this.game = game;
				} catch (error) {
					return false;
				}
			},
			getValue (bid) {
				if (bid.type === 'pass') {
					return 'p';
				}
				if (bid.type === 'double') {
					return 'X';
				}
				if (bid.type === 'redouble') {
					return 'XX';
				}
				if (bid.suit === 'NT') {
					return bid.value + 'NT';
				}
				return bid.value;
			},
			getHasIcon (bid) {
				if (this.getValue(bid) === 'p' ||
						this.getValue(bid) === 'X' ||
						this.getValue(bid) === 'XX') {
					return false;
				}
				return true;
			},
			getSuit (bid) {
				if (!bid.suit) {
					return false;
				}
				if (bid.suit === 'NT') {
					return false;
				}
				if (bid.suit === 'S') {
					return 'spade';
				}
				if (bid.suit === 'D') {
					return 'diamond';
				}
				if (bid.suit === 'C') {
					return 'club';
				}
				return 'heart';
			},
			getSuitColour (bid) {
				if (!bid.suit ||
						bid.suit === 'NT') {
					return '';
				}
				if (bid.suit === 'C' ||
						bid.suit === 'S') {
					return 'black';
				}
				return 'red';
			}
		}
	};

</script>

<style lang="scss" scoped>

.bidding-table {
		&__table {
			width: 280px;
			user-select: none;
			overflow: hidden;
      padding: 16px;
      border-radius: 5px;
			border: 1px solid $c-gray-l;
      display: flex;
      flex-direction: column;
      gap: 8px;

			&--positions {
				display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 4px;

				.position {
					display:flex;
					align-items:center;
					justify-content:center;
					width: 100%;
					text-transform:uppercase;

          @include font(18px, $c-darkest-navy, 500, 23px);
				}
			}

			.bids {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.5px 4px;
				width:100%;
        padding-bottom: 8px;

				max-height:rem(192);
				min-height:rem(22);

				.bid {
					display:flex;
					align-items:center;
					justify-content:center;
					width: 100%;

          padding: 5px 16px;

          border-radius: 4.5px;

          &:not(.empty-bid) {
            box-shadow: 0px 3px 7.5px 2.25px #81818114;
          }

					[data-component='icon'] {
						flex-shrink:0;
						width:auto;
						height:rem(15);
					}

					.value {
						@include font(15px, $c-darkest-navy, 400, 19px);
						text-transform:uppercase;
					}

					&:first-child {
						&[data-player='W'] {
						}
						&[data-player='N'] {
							margin-left:25%;
						}
						&[data-player='E'] {
							margin-left:50%;
						}
						&[data-player='S'] {
							margin-left:75%;
						}
					}
				}
			}
		}
	}

</style>
