<template>
	<div
		data-component="deal-matrix"
	>
		<div data-element="positions">
			<div
				data-element="position"
				v-for="(position, positionIndex) in getPositions"
				:data-position="position"
				:key="positionIndex"
			>
				<V2Hand
					:diagram="diagram"
					:position="position"
				/>
			</div>
			<compass
				:isSimulatorRemote="false"
			/>
		</div>

	</div>
</template>

<script>

	import V2Hand from '@/components/v2/diagrams/V2Hand';
	import Compass            from '@/components/diagrams/Compass';
	import diagramFormat      from '@/mixins/diagramFormat';
	import { seatPositions }  from '@/consts';

	export default {
		components: {
			V2Hand,
			Compass
		},
		mixins: [
			diagramFormat
		],
		props: {
			diagram: {
				type: Object,
				required: true
			}
		},
		data: () => ({
			seatPositions
		}),
		computed: {

			getPositions () {
				return this.seatPositions.map((seat) => {
					return seat.text.toLowerCase();
				});
			},

			getHideEmptySuits () {
				return this.diagram.config.hideEmptySuits;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-matrix'] {
		width:auto;

    ::v-deep .hand {
      padding: 8px;
    }

		&[data-answer] {
			position:relative;
			padding:16px;
			border-radius:4px;
			border:3px solid transparent;
			&[data-selectable] {
				cursor:pointer;
			}
			&[data-selected] {
				background-color:lighten($c-brand-blue-lighter-1, 50%);
				border-color:$c-brand-blue-lighter-1;
				cursor:default;
			}
			&[data-selected-and-correct-and-quiz-is-complete] {
				background-color:lighten($c-brand-green, 50%);
				border-color:$c-brand-green;
			}
			&[data-selected-and-incorrect-and-quiz-is-complete] {
				background-color:lighten($c-brand-red, 50%);
				border-color:$c-brand-red;
				&[data-selectable] {
					cursor:pointer;
				}
			}
			&[data-highlighted] {
				border-color:$c-brand-green;
			}
			[data-component='answer-icon'] {
				position:absolute;
				bottom:rem(4);
				right:rem(4);
			}
		}
		[data-element='positions'] {
			display:grid;
			grid-template-columns:fit-content(33%) fit-content(33%) auto;
			grid-template-rows:fit-content(33%) fit-content(33%) auto;
			[data-element='position'] {
				display:flex;
				margin:0;
				&[data-position='north'],
				&[data-table-position='top']{
					grid-column-start:2;
					grid-column-end:2;
					grid-row-start:1;
					grid-row-end:1;
					padding-bottom:rem(8);
          margin: auto
				}
				&[data-position='east'],
				&[data-table-position='right']{
					grid-column-start:3;
					grid-column-end:3;
					grid-row-start:2;
					grid-row-end:2;
					justify-content:flex-start;
					padding-left:rem(8);
				}
				&[data-position='south'],
				&[data-table-position='bottom']{
					grid-column-start:2;
					grid-column-end:2;
					grid-row-start:3;
					grid-row-end:3;
					justify-self:center;
					align-self:start;
					padding-top:rem(8);
				}
				&[data-position='west'],
				&[data-table-position='left']{
					grid-column-start:1;
					grid-column-end:1;
					grid-row-start:2;
					grid-row-end:2;
					padding-right:rem(8);
				}
			}
		}
	}

</style>
