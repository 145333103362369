<template>
	<div class="hand" v-show="getNumberOfCardsInHand || (!getNumberOfCardsInHand && !getHideEmptySuits)">
		<div class="suits">
			<V2Suit
				v-for="(suit, key) in suitOrder"
				:key="key"
				:suit="suit"
				:cards="getHand(suit)"
				:hideEmptySuits="getHideEmptySuits"
			/>
		</div>
	</div>
</template>

<script>

	import V2Suit            from '@/components/v2/diagrams/V2Suit';
	import { suitOrder }   from '@/consts';

	export default {
		components: {
			V2Suit
		},
		props: {
			diagram: {
				type: Object,
				required: true
			},
			position: {
				type: String,
				required: true
			}
		},
		data: () => ({
			suitOrder
		}),
		computed: {
			getShowEmptyMessage () {
				if (this.getNumberOfCardsInHand) {
					return false;
				}
				return true;
			},
			getNumberOfCardsInHand () {
				const hand = this.diagram.config[this.position];
        if (!hand) {
          return 0;
        }

				const cards = [];
				for (const suit in hand) {
					cards.push(...hand[suit]);
				}
				return cards.length;

			},
			getHideEmptySuits () {
				return this.diagram.config?.hideEmptySuits;
			}
		},
		methods: {
			getHand (suit) {
        if (!this.diagram.config[this.position]) {
          return '';
        }
				return this.diagram.config[this.position][suit] || '';
			}
		}
	};

</script>

<style lang="scss" scoped>

	.hand {
		user-select:none;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid $c-gray-l;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: auto;

    @media (max-width: 1279px) {
      min-width: auto;
    }

    .suits {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

		.empty-message {
			font-size:rem(18);
			margin:0 0 rem(8);
		}
	}

</style>
